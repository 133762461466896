import * as React from "react";
import { graphql } from 'gatsby'

import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import { PageHead } from "../components/page-head";

import FooterSection from "../components/footer-section";
import LogoSection from "../components/logo-section";
import AppButtonSection from "../components/app-button-section";
import HeaderSection from "../components/header-section";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import "@fontsource/source-sans-pro";

import "../styles/custom.sass";

const DesktopAppPage = ({data}) => {
  return (
    <>
      <HeaderSection />

      <section className="container-fluid py-4 bg-gard text-white">
        <div className="container px-3">
          <div className="row">
            <div className="col px-4 d-flex flex-column flex-sm-row gap-3 justify-content-between justify-content-sm-start justify-content-center">
              <a
                href="https://app.medicoguide.no/"
                title="Go to desktop application"
                id="getAccessButton"
                className="btn bg-medico-green d-flex flex-grow-1 flex-sm-grow-0 align-items-center
                  justify-content-center pt-3 pb-3 px-sm-5 text-white lh-base shadow-lg"
              >
                <FontAwesomeIcon icon={faDesktop} className="me-2" /> Desktop app
              </a>

              <button
                title="Go to mobile applications"
                className="btn bg-medico-green d-flex flex-grow-1 flex-sm-grow-0 align-items-center
                  justify-content-center px-sm-5 pt-3 pb-3 text-white lh-base shadow-lg"
                onClick={() => scrollTo("#app-section")}
              >
                <FontAwesomeIcon icon={faMobileAlt} className="me-2" /> Mobile app
              </button>
            </div>
          </div>
        </div>
      </section>

      <main className="container-fluid px-0">
        <section
          className="px-3 pt-5 pb-5 position-relative d-block"
          style={{ minHeight: "35vh", backgroundColor: "#f2f2f2" }}
        >
          <div className="container px-4">
            <div className="row mb-4 px-0">
              <h1 className="mb-4">
                Installing as desktop​ application (offline mode)
              </h1>
              <p>
                Please follow the instructions below for your browser of choice.
                <br />
                To install, go to{" "}
                <a href="https://app.medicoguide.no" title="Mariners Medico Guide desktop application">
                  app.medicoguide.no
                </a>
                .
              </p>
              <p>
                When the application has fully loaded, it is also accessible
                when offline.
                <br />
                For quick and easy access, we recommend adding a shortcut to the
                application.
              </p>
            </div>
            <div className="row mb-5">
              <div className="col-md-6 mb-5">
                <h2 className="h3">Chrome</h2>
                <StaticImage
                  src="../assets/medico_chrome_desktop.png"
                  alt="Medico Guide add as offline web app in Chrome"
                  width={550}
                  quality={90}
                  placeholder="blurry"
                  className="shadow-lg"
                />
              </div>
              <div className="col-md-6 mb-5">
                <h2 className="h3">Edge</h2>
                <StaticImage
                  src="../assets/medico_edge_desktop.png"
                  alt="Medico Guide add as offline web app in Edge"
                  width={550}
                  quality={90}
                  placeholder="blurry"
                  className="shadow-lg"
                />
              </div>
            </div>
            <div className="row px-0">
              <h2 className="mb-4">Supported browsers</h2>
              <p>
                <strong>Windows 10 and 11</strong>
                <br />
                Google Chrome (from version 73), Microsoft Edge (from version 79)
              </p>
              <p>
                <strong>ChromeOS</strong>
                <br />
                Built-in Chrome browser (from version 72)
              </p>
              <p>
                <strong>macOS, Linux, and Windows 7 and 8.x</strong>
                <br />
                Google Chrome (from version 73), Microsoft Edge
              </p>
            </div>
          </div>
        </section>
        <AppButtonSection remove="desktop" />
        <LogoSection />
      </main>
      <FooterSection currentYear={new Date().getFullYear()} />
    </>
  );
};

export default DesktopAppPage;

export const query  = graphql`
  query DesktopAppQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`

export const Head = ({ location }) => {
  return(
    <>
      <PageHead pathname={location.pathname} subTitle="Download desktop app">
        <meta name="description" content="Guide to download the Mariners Medico Guide Desktop application (offline mode)" />
      </PageHead>
    </>
  )
}
